@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--colorFail);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 48px 0;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 60px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    padding: 6px 0 2px 0;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 0px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  composes: clearfix from global;
  border-top: 1px solid var(--colorGrey100);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--colorGrey100);
  }
}
.listings {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* .listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
} */

.listing {
  width: 100%;
}

.mobileReviewsTitle {
  margin: 24px 0 26px 0;
}

.mobileReviews {
  border-top: 1px solid var(--colorGrey100);
  margin-bottom: 36px;
}

.desktopReviews {
  border-top: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.desktopReviewsWrapper {
  max-width: 600px;
  margin-top: 28px;
}

.desktopReviewsTitle {
  composes: textLarge from global;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}
.username {
  margin: 0;
  color: var(--colorGrey400);
}
